import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import GAALogo from "../../assets/images/GAA-Logo-white.png";
import "./login.css";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      localStorage.clear();
      AuthService.login(username, password).then(
        (response) => {
          if (response.reset_pass === "0") {
            navigate("/ForceChangePassword");
          } else if (
            response.role_id === "1" &&
            response.occsts === "1" &&
            response.reset_pass === "1"
          ) {
            navigate("/Dashboard");
          } else if (
            response.role_id === "2" &&
            response.occsts === "1" &&
            response.reset_pass === "1"
          ) {
            navigate("/Dashboard");
          } else if (
            response.role_id === "4" &&
            response.occsts === "1" &&
            response.reset_pass === "1"
          ) {
            navigate("/Dashboard");
          } else if (
            response.role_id === "6" &&
            response.occsts === "1" &&
            response.reset_pass === "1"
          ) {
            navigate("/Dashboard");
            // window.location.reload();
          } else if (response.occsts === "0" && response.reset_pass === "1") {
            navigate("/Onboard");
          } else if (response.error !== "") {
            toast.warn(response.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);

          toast.warn(resMessage, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleGuestLogin = (e) => {
    e.preventDefault();
    navigate("/CourseCatalog");
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Form onSubmit={handleLogin} ref={form}>
                <div className="login active">
                  <div className="heading login-heading">
                    <img src={GAALogo} width="175px" alt="" />
                    <p>
                      Login Into your account to continue with your learning
                      experience.
                    </p>
                  </div>
                  <ToastContainer />
                  <div className="form">
                    <div className="form-group">
                      <input
                        type="text"
                        id="username"
                        name="username"
                        className="input"
                        required
                        value={username}
                        onChange={onChangeUsername}
                        validations={[required]}
                      />
                      <label htmlFor="">Username or Email</label>
                    </div>
                    <div className="form-group togglePassword">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        className="input password"
                        required
                        value={password}
                        onChange={onChangePassword}
                        validations={[required]}
                      />
                      <label htmlFor="">Password</label>
                      <i
                        className={
                          showPassword ? "uil uil-eye" : "uil uil-eye-slash"
                        }
                        id="eye-icon"
                        onClick={togglePassword}
                      ></i>
                    </div>
                    <div className="form-group">
                      <div className="remb-me-flex">
                        <div className="remember-me">
                          <input
                            type="checkbox"
                            name="rememberMe"
                            id="rememberMe"
                          />
                          <label htmlFor="rememberMe">
                            <span>Remember Me</span>
                          </label>
                        </div>
                        <div className="forget-psw">
                          <a href="./ForgotPassword">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                    <div className="form-group action">
                      <div className="flex_form-group">
                        <button
                          className="fill_btn"
                          type="submit"
                          name="btn_login"
                          disabled={loading}
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                        <button
                          className="fill_btn guestlogin"
                          type="submit"
                          name="btn_guestlogin"
                          onClick={handleGuestLogin}
                        >
                          Log in as a guest
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
