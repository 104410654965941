import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getInvoiceList = () => {
  return http.get("/api/invoinfo", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const genInvoice = (data) => {
  return http.post("/api/invoinfodata", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const fetchStudentLists = (data) => {
  return http.post("/api/stdvinfo", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const updateInvoiceSts = (data) => {
  return http.post("/api/upinvosts", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const updatePaymentSts = (data) => {
  return http.post("/api/uppayinvosts", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const removeStdList = (data) => {
  return http.post("api/upstdvsts", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const invoiceGraph = () => {
  return http.get("api/invographdata", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const revenueGraph = () => {
  return http.get("api/invorevn", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const verifyInvList = (data) => {
  return http.post("api/invochgup", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const getInvoiceInfo = (data) => {
  return http.post("api/rmdaccinvo", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};


const getCrNote = () => {
  return http.get("api/getcnote", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const insertCrNote = (data) => {
  return http.post("api/inscnote", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const assignCrNote = (data) => {
  return http.post("api/upcnote", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};


const InvoiceService = {
  getInvoiceList,
  genInvoice,
  fetchStudentLists,
  updateInvoiceSts,
  updatePaymentSts,
  removeStdList,
  invoiceGraph,
  verifyInvList,
  revenueGraph,
  getInvoiceInfo,
  getCrNote,
  insertCrNote,
  assignCrNote
};

export default InvoiceService;
