import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ErrorreqService from "../../../services/errorreq.service";

Chart.register(...registerables);

const PaidInvoiceChart = ({ invoiceGraphData }) => {
  const [resolvedReq, setResolvedReq] = useState("0");
  const [unresolvedReq, setUnresolvedReq] = useState("0");
  const [inprogressReq, setInprogressReq] = useState("0");

  var style = getComputedStyle(document.body);
  var whiteColor = style.getPropertyValue("--whiteColor");

  useEffect(() => {
    ErrorreqService.getErrorReq()
      .then((response) => {
        const resolvedCount = response.data.filter(
          (errorReq) => errorReq.status === "2"
        ).length;
        const unresolvedCount = response.data.filter(
          (errorReq) => errorReq.status === "0"
        ).length;
        const inprogressCount = response.data.filter(
          (errorReq) => errorReq.status === "1"
        ).length;

        setResolvedReq(
          ((resolvedCount / response.data.length) * 100).toFixed(0)
        );
        setUnresolvedReq(
          ((unresolvedCount / response.data.length) * 100).toFixed(0)
        );
        setInprogressReq(
          ((inprogressCount / response.data.length) * 100).toFixed(0)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const gradient = document.createElement("canvas").getContext("2d");
  const gradientFill = gradient.createLinearGradient(0, 0, 0, 450);
  gradientFill.addColorStop(0, "rgba(59, 0, 141, 1)"); // Start color
  gradientFill.addColorStop(1, "rgba(56, 123, 255, 1)"); // End color

  const gradientFillNew = gradient.createLinearGradient(0, 0, 0, 450);
  gradientFillNew.addColorStop(0, "rgba(206, 146, 43, 1)"); // Start color
  gradientFillNew.addColorStop(0.5, "rgba(239, 80, 86, 1)"); // End color
  gradientFillNew.addColorStop(1, "rgba(225, 112, 112, 1)"); // End color

  const data = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        label: "Payment Status",
        data: [invoiceGraphData.paid, invoiceGraphData.unpaid],
        backgroundColor: [gradientFill, gradientFillNew],
        borderColor: ["transparent"],
        hoverOffset: 4,
      },
    ],
  };

  const centerText = {
    id: "",
  };

  const options = {
    cutout: 50,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      centerText: [centerText],
    },
  };

  return (
    <>
      <div className="error-report d-flex flex-row-reverse align-items-center justify-content-around">
        <div className="my__progress-chart">
          <div className="chart">
            <Doughnut
              data={data}
              options={options}
              width="250px"
              height="250px"
            />
          </div>
        </div>
        <div className="chart__data d-flex flex-column">
          <div className="chart__point">
            <div
              className="chart__color"
              style={{ backgroundColor: "#DBD8D8" }}
            ></div>
            <div className="chart__text">
              <span>Paid</span>
            </div>
          </div>
          <div className="chart__point">
            <div
              className="chart__color"
              style={{ backgroundColor: "#FC7DAC" }}
            ></div>
            <div className="chart__text">
              <span>Unpaid</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaidInvoiceChart;
