import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";

function ActionFlex() {
  const location = useLocation();

  const [roleid, setRoleid] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setRoleid(user.role_id);
    }
  }, []);

  return (
    <>
      <div className="action-flex">
        <Link
          to="/PaidInvoice"
          className={
            location.pathname.includes("/PaidInvoice")
              ? "action-item active"
              : "action-item"
          }
        >
          <span>Paid Invoice</span>
        </Link>
        <Link
          to="/PendingInvoice"
          className={
            location.pathname.includes("/PendingInvoice")
              ? "action-item active"
              : "action-item"
          }
        >
          <span>Pending Invoice</span>
        </Link>
        {roleid === "1" || roleid === "2" ? (
          <>
            <Link
              to="/CreditNote"
              className={
                location.pathname.includes("/CreditNote")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>Credit Note</span>
            </Link>
            <Link
              to="/InvoiceReport"
              className={
                location.pathname.includes("/InvoiceReport")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>Revenue Report</span>
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ActionFlex;
