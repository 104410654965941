import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Link } from "react-router-dom";

import { Form, FloatingLabel } from "react-bootstrap";

import CourseService from "../../services/course.service";
import AuthService from "../../services/auth.service";

import DataTable from "react-data-table-component";

import Select from "react-select";

const FeedbackReport = () => {
  const [allFeedbacks, setAllFeedbacks] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [courses, setCourses] = useState([]);

  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [isFromDate, setIsFromDate] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    CourseService.getCourse(user.user)
      .then((resp) => {
        // setCourses(resp.data);
        setCourses(
          resp.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      })
      .catch((e) => console.log(e));
  }, []);

  const getActivtyLogReport = () => {
    const user = AuthService.getCurrentUser();
    const feedbackData = {
      usp: user.user,
      corsid: "",
      enrollid: "",
    };

    CourseService.getAllFeedbacks(feedbackData)
      .then((res) => {
        setAllFeedbacks(res.data);
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getActivtyLogReport();
  }, []);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.complitiondate,
      sortable: true,
      width: "125px",
    },
    {
      name: "Learner Name",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Course Name",
      selector: (row) => row.coursename,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Course Feedback",
      selector: (row) => row.course_feedback,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Course Element Feedback",
      selector: (row) => row.course_element_feedback,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: "Suggestions",
      selector: (row) => row.suggestions,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: "Review",
      cell: (row) => (
        <div className="star-rating">
          <button type="button" className="on">
            <span>{row.review}</span>
            <span className="star">&#9733;</span>
          </button>
        </div>
      ),
      selector: (row) => row.review,
      sortable: true,
    },
  ];

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filteredData = allFeedbacks.filter(
      (row) =>
        row.full_name.toLowerCase().includes(keyword) ||
        row.course_feedback.toLowerCase().includes(keyword) ||
        row.coursename.toLowerCase().includes(keyword) ||
        row.course_element_feedback.toLowerCase().includes(keyword) ||
        row.suggestions.toLowerCase().includes(keyword)
    );
    if (keyword.length > 0) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    setFilteredData(filteredData);
  };

  const handleFromDate = (e) => {
    setIsFromDate(true);
    setFromDate(e.target.value);
    applyFilters(e.target.value, toDate, selectedOptions);
  };

  const handleToDate = (e) => {
    setToDate(e.target.value);
    applyFilters(fromDate, e.target.value, selectedOptions);
  };

  const filterByCourses = (e) => {
    setSelectedOptions(e);
    applyFilters(fromDate, toDate, e);
  };

  const applyFilters = (fromDate, toDate, selectedOptions) => {
    let filteredData = allFeedbacks;

    if (fromDate) {
      filteredData = allFeedbacks.filter(
        (row) => row.complitiondate == fromDate
      );
    }

    if (fromDate && toDate) {
      filteredData = allFeedbacks.filter(
        (row) => row.complitiondate >= fromDate && row.complitiondate <= toDate
      );
    }

    if (selectedOptions && selectedOptions.label) {
      filteredData = filteredData.filter(
        (row) => row.coursename == selectedOptions.label
      );
    }

    setFilter(true);
    setFilteredData(filteredData);
  };

  const clearFilter = () => {
    setFilter(false);
    setIsFromDate(false);
    setFromDate("");
    setToDate("");
    setSelectedOptions("");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Course Feedback Report</h1>
                  </div>
                  <div className="courses-act">
                    <div className="action-item">
                      <Link to="/Reports">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Back</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="filter reports">
                  <div className="filter-heading">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.432"
                        height="15.819"
                        viewBox="0 0 24.503 22.236"
                      >
                        <g
                          id="setting-_1_"
                          transform="translate(-21.408 468.876)"
                        >
                          <path
                            id="Path_12194"
                            data-name="Path 12194"
                            d="M365.507-468.8A3.342,3.342,0,0,0,363-465.566a3.082,3.082,0,0,0,.366,1.541,3.339,3.339,0,0,0,3.338,1.781,3.279,3.279,0,0,0,2.016-1.008,3.023,3.023,0,0,0,.742-1.175,3.384,3.384,0,0,0-.935-3.646,4.2,4.2,0,0,0-1.426-.731A4.3,4.3,0,0,0,365.507-468.8Z"
                            transform="translate(-323.75 0)"
                          />
                          <path
                            id="Path_12195"
                            data-name="Path 12195"
                            d="M22.212-426.671a1.244,1.244,0,0,0-.428.261,1.076,1.076,0,0,0,.235,1.786l.277.136h6.863c5.281,0,6.916-.016,7.083-.063a1.188,1.188,0,0,0,.705-.736,1.117,1.117,0,0,0-.507-1.275l-.245-.146-6.895-.01C24.86-426.723,22.337-426.707,22.212-426.671Z"
                            transform="translate(0 -39.955)"
                          />
                          <path
                            id="Path_12196"
                            data-name="Path 12196"
                            d="M28.6-319.677a3.464,3.464,0,0,0-2.236,1.734l-.225.434h-1.88a8.787,8.787,0,0,0-2.074.078,1.114,1.114,0,0,0,0,2.037,8.787,8.787,0,0,0,2.074.078h1.88l.225.428a3.339,3.339,0,0,0,3.15,1.8,3.343,3.343,0,0,0,2.988-2.309,2.628,2.628,0,0,0,.136-1.019,2.593,2.593,0,0,0-.141-1.029,3.359,3.359,0,0,0-2.131-2.142A3.734,3.734,0,0,0,28.6-319.677Z"
                            transform="translate(-0.111 -141.345)"
                          />
                          <path
                            id="Path_12197"
                            data-name="Path 12197"
                            d="M278.32-276.916a1.113,1.113,0,0,0,.094,2.058c.136.042,1.661.052,4.931.042l4.732-.016.23-.157a1.109,1.109,0,0,0-.178-1.933,38.806,38.806,0,0,0-4.91-.078A39.328,39.328,0,0,0,278.32-276.916Z"
                            transform="translate(-242.883 -181.854)"
                          />
                          <path
                            id="Path_12198"
                            data-name="Path 12198"
                            d="M280.146-170.548a3.075,3.075,0,0,0-1.88.966,3.127,3.127,0,0,0-1,2.142,3.272,3.272,0,0,0,.935,2.5,3.089,3.089,0,0,0,2.209,1.008,3.24,3.24,0,0,0,1.708-.334,3.779,3.779,0,0,0,1.447-1.463l.183-.376,1.807-.005c2.11,0,2.215-.016,2.544-.4a1.073,1.073,0,0,0-.308-1.713l-.277-.136h-3.782l-.063-.188a3.569,3.569,0,0,0-1.588-1.687A3.532,3.532,0,0,0,280.146-170.548Z"
                            transform="translate(-242.489 -282.716)"
                          />
                          <path
                            id="Path_12199"
                            data-name="Path 12199"
                            d="M22.06-127.864a1.075,1.075,0,0,0-.575,1.243,1.2,1.2,0,0,0,.789.8c.178.047,1.473.063,4.758.063,5.124,0,4.889.016,5.249-.4a1.073,1.073,0,0,0-.308-1.713L31.7-128h-9.36Z"
                            transform="translate(-0.042 -323.071)"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="name">Filters</span>
                  </div>
                  <p id="clearFilter" onClick={clearFilter}>
                    Clear Filter
                  </p>
                </div>
                <div className="search-bar form-flex">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                    onChange={handleSearch}
                  />
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "300px",
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--dark-bg)"
                          : isFocused
                          ? "var(--dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    onChange={filterByCourses}
                    value={selectedOptions}
                    options={courses}
                    issearchable="true"
                    placeholder="Select Course Category"
                    required
                  />

                  <Form.Group
                    //controlId="formBasicOrgName"
                    className="date-input"
                  >
                    <div className="input-box">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="From Date"
                        className="mb-3"
                      >
                        <Form.Control
                          type="date"
                          name="fromDate"
                          value={fromDate}
                          onChange={handleFromDate}
                        />
                      </FloatingLabel>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14.02"
                          viewBox="0 0 81.923 76.58"
                        >
                          <g id="calendar" transform="translate(-15 482)">
                            <path
                              id="Path_13206"
                              data-name="Path 13206"
                              d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                            />
                            <path
                              id="Path_13207"
                              data-name="Path 13207"
                              d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                              transform="translate(0 -108.781)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  {isFromDate && (
                    <Form.Group
                      // controlId="formBasicOrgName"
                      className="date-input"
                    >
                      <div className="input-box">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="To Date"
                          className="mb-3"
                        >
                          <Form.Control
                            type="date"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDate}
                          />
                        </FloatingLabel>
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14.02"
                            viewBox="0 0 81.923 76.58"
                          >
                            <g id="calendar" transform="translate(-15 482)">
                              <path
                                id="Path_13206"
                                data-name="Path 13206"
                                d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                              />
                              <path
                                id="Path_13207"
                                data-name="Path 13207"
                                d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                transform="translate(0 -108.781)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                  )}
                </div>
                <div className="admin-users">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filter ? filteredData : allFeedbacks}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackReport;
