import React, { useState, useEffect } from "react";
import ActionFlex from "./ActionFlex";
import "./accounts.css";
import { Link } from "react-router-dom";
import "../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import InvoiceService from "../../services/invoice.service";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";

function CreditNote({ roleid }) {
  const [details, setDetails] = useState({
    selectedInvoice: "",
    crdNoteList: [],
    invoiceLists: [],
    addCreditNote: false,
    invoiceNo: "",
    creditNote: "",
  });

  const handleCloseCreditNote = () => {
    setDetails((prevState) => ({
      ...prevState,
      addCreditNote: false,
    }));
  };

  useEffect(() => {
    getCrdNote();
    getInvoiceList();
  }, []);

  const getCrdNote = () => {
    InvoiceService.getCrNote()
      .then((resp) => {
        console.log(resp.data);
        setDetails((prevState) => ({
          ...prevState,
          crdNoteList: resp.data,
        }));
        loadDatatable();
      })
      .catch((e) => console.log(e));
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#allLearners")) {
      function filterGlobal() {
        $("#allLearners")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#allLearners")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#allLearners").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const getInvoiceList = () => {
    InvoiceService.getInvoiceList()
      .then((resp) => {
        const invList = resp.data.filter(
          (item) => item.invoice.statusflag === "3"
        );
        console.log(invList);
        setDetails((prevState) => ({
          ...prevState,
          invoiceLists: invList,
        }));
      })
      .catch((e) => console.log(e));
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const splitedData = selectedValue.split("-");
    setDetails((prevState) => ({
      ...prevState,
      selectedInvoice: selectedValue,
      addCreditNote: true,
      invoiceNo: splitedData[1],
      creditNote: splitedData[0],
    }))
  };

  const handleApplyCreditNote = () => {
    const payload = {
      oninvoiceno: details.invoiceNo,
      invosts: "1",
      cnid: details.creditNote
    }
    console.log(payload);

    InvoiceService.assignCrNote(payload)
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex />
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="allLearners">
                      <thead>
                        <tr>
                          <th>Credit Note No.</th>
                          <th>Raised on</th>
                          <th>Against</th>
                          <th>Amount</th>
                          <th>Reason</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(details.crdNoteList)
                          ? details.crdNoteList.map((crdNote) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <span>{crdNote.cncode}</span>
                                    </td>
                                    <td>
                                      <span>{crdNote.raisedon_invoice}</span>
                                    </td>
                                    <td>
                                      <span>{crdNote.againson_invoice}</span>
                                    </td>
                                    <td>
                                      <span>{crdNote.Amount}</span>
                                    </td>
                                    <td>
                                      <span>{crdNote.reason}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {crdNote.status === "1"
                                          ? "Used"
                                          : "Not Used"}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="assign-req invoice">
                                        <div className="select">
                                          <select
                                            name=""
                                            id=""
                                            value={details.selectedInvoice}
                                            onChange={handleSelectChange}
                                          >
                                            <option value="NA">Assign</option>
                                            {Array.isArray(details.invoiceLists)
                                              ? details.invoiceLists.map((item) => {
                                                  return (
                                                    <>
                                                      <option
                                                        value={`${crdNote.cncode}-${item.invoice.invoice_no}`}
                                                      >
                                                        {
                                                          item.invoice
                                                            .invoice_no
                                                        }
                                                      </option>
                                                    </>
                                                  );
                                                })
                                              : null}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={details.addCreditNote} onHide={handleCloseCreditNote} centered>
        <Modal.Header>
          <Modal.Title>Assign Credit Note</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCloseCreditNote}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to apply credit note to this invoice.
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handleCloseCreditNote}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleApplyCreditNote();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreditNote;
