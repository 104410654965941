import React, { useState, useEffect } from "react";
import "./UserManagement.css";
import "./responsive.css";
import Select from "react-select";

import Filters from "./Filters";
import ActionFlex from "./ActionFlex";
import { Link } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CohortsService from "../../../services/cohorts.service";
import UserService from "../../../services/user.service";
import CourseService from "../../../services/course.service";
import AuthService from "../../../services/auth.service";

import DataTable from "react-data-table-component";

const Cohorts = ({ permissions, roleid }) => {
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disableBulkDelete, setDisableBulkDelete] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dataUpload, setDataUpload] = useState(false);
  const [did, setDid] = useState("");
  const [uid, setUid] = useState("");
  const [addCourses, setAddCourses] = useState({
    selectedCohorts: [],
    courseId: [],
  });

  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [options, setOptions] = useState([]);

  const [allCohots, setAllCohots] = useState("0");

  const [activeCohorts, setActiveCohorts] = useState("");
  const [inActiveCohorts, setInactiveCohorts] = useState("");

  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [isFromDate, setIsFromDate] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [selectedRows, setSelectedRows] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const handleChange = ({ selectedRows }) => {
    const selectedIds = selectedRows.map((row) => row.user_id);
    setSelectedRows(selectedIds);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const getCourseData = () => {
    CourseService.getCourse()
      .then((response) => {
        const courses = response.data;
        const filteredCourse = courses.filter(
          (course) => course.pubsts === "1"
        );

        setOptions(
          filteredCourse.map((course) => ({
            value: course.id,
            label: course.name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStaffsData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((response) => {
        setStaffs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCohotsData = () => {
    const user = AuthService.getCurrentUser();
    CohortsService.getCohorts(user.user)
      .then((response) => {
        const cohotData = response.data;

        console.log("cohotData", response.data);

        if (Array.isArray(cohotData)) {
          setAllCohots(cohotData.length);
          setCohorts(cohotData);
          setActiveCohorts(
            Array.isArray(cohotData)
              ? cohotData.filter((cohot) => cohot.status === "1").length
              : null
          );
          setInactiveCohorts(
            Array.isArray(cohotData)
              ? cohotData.filter((cohot) => cohot.status === "0").length
              : null
          );
        } else {
          setAllCohots("0");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      name: "Cohort Name",
      cell: (row) => (
        <div className="user-flex">
          <div className="user-info">
            <span className="name">{row.name}</span>
          </div>
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Created. On",
      cell: (row) => {
        // const [date, time] = row.date.split(" ");
        return <span>{row.date}</span>;
      },
      selector: (row) => row.date,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div
          className={
            row.status === "1" ? "user-status active" : "user-status inactive"
          }
        >
          {row.status}
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Participants",
      cell: (row) => (
        <span>{row.student ? row.student.split(",").length : 0}</span>
      ),
      selector: (row) => row.student.split(",").length,
      sortable: true,
      width: "200px",
    },
    {
      name: "Cohort Courses",
      selector: (row) => row.coursescount,
      sortable: true,
      width: "175px",
    },
    {
      name: "Managed by",
      cell: (row) => <span>{getStaffName(row.created_by, staffs)}</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="table-act">
          {(permissions && permissions.includes("mgc5")) ||
          roleid === "1" ||
          roleid === "2" ? (
            <>
              <button className="manage-learners" title="Manage Learners">
                <Link
                  to={"/ManageCohortParticipants"}
                  state={{
                    id: row.id,
                    courses_count: row.coursescount,
                  }}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="15"
                      viewBox="0 0 164.427 134.024"
                    >
                      <g id="user-_3_" transform="translate(-56.783 418.477)">
                        <path
                          id="Path_14330"
                          data-name="Path 14330"
                          d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                          transform="translate(-40.783)"
                        />
                        <path
                          id="Path_14331"
                          data-name="Path 14331"
                          d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                          transform="translate(-119.996 -69.616)"
                        />
                        <path
                          id="Path_14332"
                          data-name="Path 14332"
                          d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                          transform="translate(0 -97.978)"
                        />
                      </g>
                    </svg>
                  </span>
                </Link>
              </button>
            </>
          ) : (
            <></>
          )}

          {(permissions && permissions.includes("mgc3")) ||
          roleid === "1" ||
          roleid === "2" ? (
            <>
              <button className="edit" title="Edit Cohort">
                <Link to={"/EditCohort"} state={{ id: row.id }}>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 22.58 22.583"
                    >
                      <g
                        id="Group_11449"
                        data-name="Group 11449"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                          transform="translate(752.656 5510.995)"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                          transform="translate(751.921 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </Link>
              </button>
            </>
          ) : (
            <></>
          )}
          {(permissions && permissions.includes("mgc4")) ||
          roleid === "1" ||
          roleid === "2" ? (
            <>
              <button
                onClick={handleShow}
                data-bs-target={row.id}
                className="delete"
                title="Delete Cohort"
              >
                <span>
                  <svg
                    id="delete"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="15"
                    viewBox="0 0 16.079 20.664"
                  >
                    <path
                      id="Path_13633"
                      data-name="Path 13633"
                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                      transform="translate(-57 512)"
                    />
                    <path
                      id="Path_13634"
                      data-name="Path 13634"
                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                      transform="translate(-83.869 402.606)"
                    />
                  </svg>
                </span>
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
      sortable: true,
      width: "175px",
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const keyword = e.target.value.toLowerCase();
    const filteredData = cohorts.filter((row) =>
      row.name && row.name.toLowerCase().includes(keyword)
    );
    if (keyword.length > 0) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    setFilteredData(filteredData);
  };

  const filterData = (sts) => {
    console.log(sts);
    if (sts === "all") {
      setFilteredData(cohorts);
    } else {
      const filteredData = cohorts.filter((row) => row.status == sts);
      if (sts !== "all") {
        setFilter(true);
      } else {
        setFilter(false);
      }
      setFilteredData(filteredData);
    }
  };

  const handleFromDate = (e) => {
    setIsFromDate(true);
    setFromDate(e.target.value);
    const filteredData = cohorts.filter(
      (row) => row.created_on.split(" ")[0] == e.target.value
    );
    console.log("filteredData", filteredData);
    setFilter(true);
    setFilteredData(filteredData);
  };

  const handleToDate = (e) => {
    setToDate(e.target.value);
    const filteredData = cohorts.filter(
      (row) =>
        row.created_on.split(" ")[0] >= fromDate &&
        row.created_on.split(" ")[0] <= e.target.value
    );

    setFilter(true);
    setFilteredData(filteredData);
  };

  const clearFilter = () => {
    setFilter(false);
    setFromDate("");
    setToDate("");
    setSearch("");
    setIsFromDate(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUid(user.user);
    getCohotsData();
    getStaffsData(user.user);
    getCourseData();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleDeleteClose = () => setDeleteSelected(false);

  const handleDeleteShow = (e) => {
    setDeleteSelected(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    const valSplit = values.split(",");

    if (valSplit[0] !== "") {
      setAddCourses({ ...addCourses, courseId: valSplit });
    } else {
      setAddCourses({ ...addCourses, courseId: [] });
    }
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allLearners = cohorts.map((l) => l.id);
      setChecked(allLearners);
      setSelectedCohorts(allLearners);
      setDisableBulkDelete(false);
    } else {
      setChecked([]);
      setSelectedCohorts([]);
      setDisableBulkDelete(true);
    }
  };

  const handleLearnerChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checked, l.id];
      setChecked([...checked, l.id]);
      setSelectedCohorts([...checked, l.id]);
    } else {
      selectedUserArr = checked.filter((item) => item !== l.id);
      setChecked(checked.filter((item) => item !== l.id));
      setSelectedCohorts(checked.filter((item) => item !== l.id));
    }
    if (selectedUserArr.length > 0) {
      setDisableBulkDelete(false);
    } else {
      setDisableBulkDelete(true);
    }
  };

  const assignCourses = (e) => {
    e.preventDefault();

    const data = {
      usp: uid,
      cohotids: selectedCohorts,
      courseids: addCourses.courseId,
    };

    CohortsService.addCoursesInCohots(data)
      .then((response) => {
        if (response.data === true) {
          toast.success(
            "Selected Learners added in selected cohorts successfully!",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.warn("Somthing went wrong please try again.!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        setChecked([]);
        setAddCourses({ ...addCourses, courseId: [] });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteSelectedCohorts = (e) => {
    e.preventDefault();
    const data = {
      usp: uid,
      delid: selectedCohorts,
    };

    CohortsService.deleteSelectedCohorts(data)
      .then((response) => {
        setDeleteSelected(false);

        toast.success("Selected cohorts deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setChecked([]);

        const user = AuthService.getCurrentUser();

        CohortsService.getCohorts(user.user)
          .then((resp) => {
            setCohorts(resp.data);
          })
          .catch((e) => {
            console.log(e);
          });

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteCohort = (e) => {
    e.preventDefault();

    CohortsService.deleteCohorts(uid, did)
      .then((response) => {
        toast.success("Cohort Deleted Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setCohorts(cohorts.filter((cohort) => cohort.id !== did));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getStaffName(staffid, staffs) {
    const staff = staffs.find((r) => r.user_id === staffid);
    return staff ? staff.first_name + " " + staff.last_name : "Super Admin";
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters
              filterRoleName={null}
              clearFilter={clearFilter}
              fromDate={fromDate}
              toDate={toDate}
              roleName={null}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              isFromDate={isFromDate}
            />
          </div>
          <div className="col-xl-9  col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex permissions={permissions} roleid={roleid} />
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <div className="act-flex">
                  <div className="permission-flex">
                    <div className="multiple-permission">
                      <div className="">
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "100%",
                              border: "none",
                              outline: "none",
                              borderRadius: "10px",
                              backgroundColor: state.isFocused
                                ? "var(--dark-bg)"
                                : "var(--dark-bg)",
                            }),
                            option: (
                              baseStyles,
                              { isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "var(--primary-color)"
                                : isFocused
                                ? "var(--secondary-color)"
                                : "var(--dark-bg)",
                              color: isSelected
                                ? "var(--body-dark-bg)"
                                : isFocused
                                ? "var(--body-dark-bg)"
                                : "var(--whiteColor)",
                            }),
                          }}
                          // className="react-select-container"
                          // classNamePrefix="react-select"
                          onChange={handleOptionChange}
                          // value={selectedOptions}
                          options={options}
                          isMulti={true}
                          issearchable="true"
                          placeholder="Quick Assign multiple courses"
                          value={
                            Array.isArray(options)
                              ? options.filter((option) =>
                                  addCourses.courseId.includes(option.value)
                                )
                              : []
                          }
                        />
                      </div>
                    </div>
                    <div className="act-permission">
                      <button onClick={assignCourses}>Assign</button>
                      {(permissions && permissions.includes("mgc4")) ||
                      roleid === "1" ? (
                        <>
                          <button
                            onClick={handleDeleteShow}
                            disabled={disableBulkDelete}
                          >
                            Delete
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="user-status-flex">
                  <div
                    className="user-status all"
                    id="allCohots"
                    onClick={(e) => {
                      filterData("all");
                    }}
                  >
                    <span>All</span>
                    <span>{allCohots}</span>
                  </div>
                  <div
                    className="user-status active"
                    id="activeCohots"
                    onClick={(e) => {
                      filterData(1);
                    }}
                  >
                    <span>Active</span>
                    <span>{activeCohorts}</span>
                  </div>
                  <div
                    className="user-status inactive"
                    id="inActiveCohots"
                    onClick={(e) => {
                      filterData(0);
                    }}
                  >
                    <span>Inactive</span>
                    <span>{inActiveCohorts}</span>
                  </div>
                </div>
                <ToastContainer />
                <div className="admin-users cohorts">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filter ? filteredData : cohorts}
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleChange}
                      clearSelectedRows={toggledClearRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Cohort</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this cohort?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteCohort}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Cohorts</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Cohorts?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteSelectedCohorts}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cohorts;
