import React from "react";

const TextButton = ({ text, onClick }) => {
  return (
    <>
      <button className="TextButton" onClick={onClick}>
        {text}
      </button>
    </>
  );
};
export default TextButton;
