import React, { useState, useEffect } from "react";
import "./UserManagement.css";
import AdminUser from "../../../assets/images/profile.jpg";
import Filters from "./Filters";
import ActionFlex from "./ActionFlex";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../../services/user.service";
import PermissionService from "../../../services/permission.service";
import AuthService from "../../../services/auth.service";

import DataTable from "react-data-table-component";

const SystemManagers = () => {
  const [checked, setChecked] = useState([]);
  const [show, setShow] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [systemManagers, setSystemManagers] = useState([]);
  const [disableBulkDelete, setDisableBulkDelete] = useState(true);
  const [allAdmins, setAllAdmins] = useState("0");
  const [activeAdmins, setActiveAdmins] = useState("0");
  const [inactiveAdmins, setInactiveAdmins] = useState("0");

  const [selectedRows, setSelectedRows] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const handleChange = ({ selectedRows }) => {
    const selectedIds = selectedRows.map((row) => row.user_id);
    setSelectedRows(selectedIds);
  };

  const [allRoles, setAllRoles] = useState([]);

  const [roleName, setRoleName] = useState("");

  const [isFromDate, setIsFromDate] = useState(false);

  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div className="user-flex">
          <div className="user-pic">
            <img src={AdminUser} width="40px" alt="" />
          </div>
          <div className="user-info">
            <span className="name">
              {row.first_name} {row.last_name}
            </span>
            <span className="role">{row.email}</span>
          </div>
        </div>
      ),
      selector: (row) => row.email,
      sortable: true,
      width: "350px",
      fixed: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div
          className={
            row.status === "1" ? "user-status active" : "user-status inactive"
          }
        >
          {row.status}
        </div>
      ),
      selector: (row) => row.status,
      sortable: true,
      width: "100px",
    },
    {
      name: "Roles",
      cell: (row) => (
        <div className="">
          <span>{getRoleName(row.role_id, allRoles)}</span>
        </div>
      ),
      selector: (row) => row.role_id,
      sortable: true,
      width: "150px",
    },
    {
      name: "Reg. On",
      selector: (row) => row.created_on,
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="table-act">
          <button className="edit">
            <Link
              to={"/EditSystemManagers"}
              state={{ id: row.user_id }}
              title="Edit User"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 22.58 22.583"
                >
                  <g
                    id="Group_11449"
                    data-name="Group 11449"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                      transform="translate(752.656 5510.995)"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                      transform="translate(751.921 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </Link>
          </button>
          <button
            onClick={handleShow}
            data-bs-target={row.user_id}
            className="delete"
            title="Delete User"
          >
            <span>
              <svg
                id="delete"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="15"
                viewBox="0 0 16.079 20.664"
              >
                <path
                  id="Path_13633"
                  data-name="Path 13633"
                  d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                  transform="translate(-57 512)"
                />
                <path
                  id="Path_13634"
                  data-name="Path 13634"
                  d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                  transform="translate(-83.869 402.606)"
                />
              </svg>
            </span>
          </button>
          <div className="form-check form-switch">
            <input
              className="form-check-input suspend"
              type="checkbox"
              role="switch"
              id="suspend-user"
              value={row.status}
              checked={row.status === "1"}
              onChange={(e) => handleSuspendShow(e, row.user_id, row.status)}
              title={row.status === "1" ? "Suspend User" : "Activate User"}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            ></label>
          </div>
        </div>
      ),
      selector: (row) => row.email,
      sortable: true,
      // width: "200px",
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const keyword = e.target.value.toLowerCase();
    const filteredData = systemManagers.filter(
      (row) =>
        row.first_name.toLowerCase().includes(keyword) ||
        row.last_name.toLowerCase().includes(keyword) ||
        row.email.toLowerCase().includes(keyword)
    );
    if (keyword.length > 0) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    setFilteredData(filteredData);
  };

  const filterData = (sts) => {
    console.log(sts);
    if (sts === "all") {
      setFilteredData(systemManagers);
    } else if (sts === "unassigned") {
      const filteredData = systemManagers.filter(
        (row) => row.course_count === null
      );
      if (sts !== "all") {
        setFilter(true);
      } else {
        setFilter(false);
      }
      setFilteredData(filteredData);
    } else {
      const filteredData = systemManagers.filter((row) => row.status == sts);
      if (sts !== "all") {
        setFilter(true);
      } else {
        setFilter(false);
      }
      setFilteredData(filteredData);
    }
  };

  const handleFromDate = (e) => {
    setIsFromDate(true);
    setFromDate(e.target.value);
    applyFilters(e.target.value, toDate, roleName);
  };
  
  const handleToDate = (e) => {
    setToDate(e.target.value);
    applyFilters(fromDate, e.target.value, roleName);
  };

  const filterRoleName = (e) => {
    setRoleName(e.target.value);
    applyFilters(fromDate, toDate, e.target.value);
  };
  
  const applyFilters = (fromDate, toDate, selectedOptions) => {
    let filteredData = systemManagers;
  
    if (fromDate) {
      filteredData = systemManagers.filter(
        (row) => row.created_on == fromDate
      );
    }
  
    if (fromDate && toDate) {
      filteredData = systemManagers.filter(
        (row) => row.created_on >= fromDate && row.created_on <= toDate
      );
    }
  
    if (selectedOptions) {
      filteredData = filteredData.filter(
        (row) => row.role_id == selectedOptions
      );
    }
  
    setFilter(true);
    setFilteredData(filteredData);
  };

  const clearFilter = () => {
    setFilter(false);
    setFromDate("");
    setToDate("");
    setRoleName("");
    setSearch("");
    setIsFromDate(false);
  }

  const [did, setDid] = useState("");
  const [uid, setUid] = useState("");

  const [suspendId, setSuspendId] = useState("");
  const [suspendSts, setSuspendSts] = useState("");

  const [role, setRole] = useState({
    delid: [],
  });

  const getStaffData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((response) => {
        setSystemManagers(response.data);
        const staffdatas = response.data;
        getStaffCount(staffdatas);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStaffCount = (staffdatas) => {
    setAllAdmins(Array.isArray(staffdatas) ? staffdatas.length : null);
    setActiveAdmins(
      Array.isArray(staffdatas)
        ? staffdatas.filter((admin) => admin.status === "1").length
        : null
    );

    setInactiveAdmins(
      Array.isArray(staffdatas)
        ? staffdatas.filter((admin) => admin.status === "0").length
        : null
    );
  };

  const getAllRolesData = () => {
    PermissionService.getAllRoles()
      .then((response) => {
        setAllRoles(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUid(user.user);
    getStaffData(user.user);
    getAllRolesData();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleSuspendClose = () => setSuspend(false);
  const handleSuspendShow = (e, uid, sts) => {
    setSuspend(true);
    setSuspendId(uid);
    setSuspendSts(sts === "0" ? "1" : "0");
  };

  const handleDeleteClose = () => setDeleteSelected(false);

  const handleDeleteShow = (e) => {
    setDeleteSelected(true);
  };

  const deleteSelectedUser = (e) => {
    e.preventDefault();
    const data = {
      usp: uid,
      // delid: role.delid,
      delid: selectedRows,
    };

    UserService.deleteMultipleSystemManagers(data)
      .then((response) => {
        toast.success("Selected Users deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setChecked([]);

        setShow(false);
        setSystemManagers(systemManagers.filter((admin) => admin));
        setDeleteSelected(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteUser = (e) => {
    e.preventDefault();
    UserService.deleteSystemManagers(uid, did)
      .then((response) => {
        toast.success("User deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setSystemManagers(
          systemManagers.filter((admin) => admin.user_id !== did)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const suspendUser = (e) => {
    e.preventDefault();
    const data = {
      uid: suspendId,
      sts: suspendSts,
    };
    UserService.suspendUsers(data)
      .then((response) => {
        toast.success("User suspended successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setSuspend(false);
        const user = AuthService.getCurrentUser();
        getStaffData(user.user);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getRoleName(roleid, allRoles) {
    const role = allRoles.find((r) => r.id === roleid);
    return role ? role.name : "";
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters
              filterRoleName={filterRoleName}
              clearFilter={clearFilter}
              fromDate={fromDate}
              toDate={toDate}
              roleName={roleName}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              isFromDate={isFromDate}
            />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex />
                <div className="act-flex">
                  <div className="search-bar admin">
                    <input
                      type="text"
                      className="global_filter"
                      id="global_filter"
                      placeholder="Start typing to search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="permission-flex admin">
                    <div className="preset-roles">
                      <Link to="/PresetRoles">
                        <button>All Roles List</button>
                      </Link>
                    </div>
                    <div className="act-permission">
                      <button
                        onClick={handleDeleteShow}
                        disabled={disableBulkDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div className="user-status-flex">
                  <div
                    className="user-status all"
                    id="allButton"
                    onClick={(e) => {
                      filterData("all");
                    }}
                  >
                    <span>All</span>
                    <span>{allAdmins}</span>
                  </div>
                  <div
                    className="user-status active"
                    id="activeStaffButton"
                    onClick={(e) => {
                      filterData(1);
                    }}
                  >
                    <span>Active</span>
                    <span>{activeAdmins}</span>
                  </div>
                  <div
                    className="user-status inactive"
                    id="inactiveButton"
                    onClick={(e) => {
                      filterData(0);
                    }}
                  >
                    <span>Inactive</span>
                    <span>{inactiveAdmins}</span>
                  </div>
                </div>
                <ToastContainer />
                <div className="admin-users">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filter ? filteredData : systemManagers}
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleChange}
                      clearSelectedRows={toggledClearRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Users</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Users?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={suspend} onHide={handleSuspendClose} centered>
        <Modal.Header>
          <Modal.Title>
            {suspendSts === "0" ? <>Suspend Users</> : <>Activate Users</>}
          </Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleSuspendClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            {suspendSts === "0" ? (
              <>Are you sure you want to suspend this User?</>
            ) : (
              <>Are you sure you want to activate this User?</>
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleSuspendClose}>
            No
          </button>
          <button className="yes" onClick={suspendUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Users</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Users?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteSelectedUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SystemManagers;
