import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import "./invoice.css";
import Invoice from "./Invoice";

const NewInvoice = ({ invoiceDetails, otherInvDetails }) => {
  const invoiceRef = useRef(null);

  const downloadPDF = async () => {
    const opt = {
      margin: 0.1,
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(invoiceRef.current).set(opt).save();

    const pdf = html2pdf().from(invoiceRef.current).set(opt);
    const blob = await pdf.output("blob");
  };

  return (
    <>
      <Invoice
        invoiceDetails={invoiceDetails}
        otherInvDetails={otherInvDetails}
        invoiceRef={invoiceRef}
      />
      <button className="cert-down" onClick={downloadPDF}>
        Download PDF
      </button>
    </>
  );
};

export default NewInvoice;
