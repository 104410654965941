import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Revenue = ({ yAxisData, revenue, themeMode }) => {
  const gradient = document.createElement("canvas").getContext("2d");
  const gradientFill = gradient.createLinearGradient(0, 0, 0, 450);

  if (themeMode === "0") {
    gradientFill.addColorStop(0, "rgba(27, 202, 155, 1)"); // Start color
    gradientFill.addColorStop(1, "rgba(27, 202, 155, 0.1)"); // End color
    var whiteColor = "rgba(255, 255, 255, 0.2)";
  } else {
    gradientFill.addColorStop(0, "rgba(27, 202, 155, 1)"); // Start color
    gradientFill.addColorStop(1, "rgba(27, 202, 155, 0.3)"); // End color
    var whiteColor = "rgba(0, 0, 0, 0.1)";
  }

  const data = {
    labels: yAxisData,
    datasets: [
      {
        label: "Revenue in BHD",
        data: revenue,
        backgroundColor: gradientFill,
        borderColor: "rgba(27, 202, 155, 1)",
        fill: true,
        borderWidth: 2,
        tension: 0.5,
        // borderRadius: 20,
        // borderSkipped: false,
        // barPercentage: 0.2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: (ctx) => "Monthly Revenue Statistic (Revenue in BHD)",
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: true,
          color: whiteColor,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: true,
          color: whiteColor,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 2, // set the step size to 1 to display integer values
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       // position: 'top' as const,
  //     },
  //     title: {
  //       display: true,
  //       text: "Chart.js Line Chart",
  //     },
  //   },
  // };

  return (
    <>
      <div className="line-area" style={{height: "290px"}}>
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default Revenue;
