import React, { useEffect, useState } from "react";
import "./error.css";
import { Link, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Modal } from "react-bootstrap";

import { Form, FloatingLabel } from "react-bootstrap";

import ModalImage from "react-modal-image";

import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import AuthService from "../../../services/auth.service";

import ErrorreqService from "../../../services/errorreq.service";
import UserService from "../../../services/user.service";

import DataTable from "react-data-table-component";

const ErrorRequest = () => {
  const [show, setShow] = useState(false);
  const [showChnagests, setShowChnagests] = useState(false);

  const [errorReqs, setErrorReqs] = useState([]);

  const [resolvedReq, setResolvedReq] = useState("0");
  const [unresolvedReq, setUnresolvedReq] = useState("0");
  const [inprogressReq, setInprogressReq] = useState("0");

  const [roleid, setRoleid] = useState("");
  const [userId, setUserId] = useState("");

  const handleClose = () => setShow(false);
  const handleCloseSts = () => setShowChnagests(false);

  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [isFromDate, setIsFromDate] = useState(false);

  const [assignReqData, setAssignReqData] = useState({
    usp: "",
    issueID: "",
    assignTo: "",
  });

  const [solveReqData, setSolveReqData] = useState({
    usp: "",
    issueID: "",
    status: "",
  });

  const handleShow = (e, reqid) => {
    setShow(true);
    const user = AuthService.getCurrentUser();
    setAssignReqData({
      usp: user.user,
      issueID: reqid,
      assignTo: e.target.value,
    });
  };

  const handleShowSts = (e, reqid) => {
    setShowChnagests(true);
    const user = AuthService.getCurrentUser();
    setSolveReqData({
      usp: user.user,
      issueID: reqid,
      status: e.target.value,
    });
  };

  // functions

  const [learners, setLearners] = useState([]);
  const [admins, setAdmins] = useState([]);

  const loadDatatable = (reqData) => {
    if (reqData.length > 0) {
      if (!$.fn.DataTable.isDataTable("#dtHorizontalExample")) {
        function filterGlobal() {
          $("#dtHorizontalExample")
            .DataTable()
            .search(
              $("#global_filter").val(),
              $("#global_regex").prop("checked"),
              $("#global_smart").prop("checked")
            )
            .draw();
        }
        function filterColumn(i) {
          $("#dtHorizontalExample")
            .DataTable()
            .column(i)
            .search(
              $("#col" + i + "_filter").val(),
              $("#col" + i + "_regex").prop("checked"),
              $("#col" + i + "_smart").prop("checked")
            )
            .draw();
        }

        $.fn.dataTable.ext.search.pop();

        // Function to apply the custom filter
        function applyStatusFilter(status) {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var rowData = data[5];

            if (rowData === status) {
              return true;
            }
            return false;
          });

          $("#dtHorizontalExample").DataTable().draw();
        }

        function compareDates() {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var minDate = $("#minDate").val();
            var maxDate = $("#maxDate").val();

            let date = data[5];

            if (minDate === date || (minDate <= date && date <= maxDate)) {
              return true;
            }
            return false;

            // if (
            //   minDate === date ||
            //   (minDate === "" && maxDate === "") || // No date range selected
            //   (minDate === "" && date <= maxDate) || // Only maxDate is specified
            //   (minDate <= date && maxDate === "") || // Only minDate is specified
            //   (minDate <= date && date <= maxDate) // Both minDate and maxDate are specified
            // ) {
            //   return true;
            // }
            // return false;
          });

          $("#dtHorizontalExample").DataTable().draw();

          // // Clear the custom filter if the date input is empty
          // if (!$(this).val()) {
          //   $.fn.dataTable.ext.search.pop();
          // }

          if ($("#minDate").val() === "" && $("#maxDate").val() === "") {
            $.fn.dataTable.ext.search.pop();
          }
        }

        // Button click event handlers
        $("#resolvedRequests").on("click", function () {
          applyStatusFilter("2");
        });

        $("#unresolvedRequests").on("click", function () {
          applyStatusFilter("0");
        });

        $("#inprogressRequests").on("click", function () {
          applyStatusFilter("1");
        });

        $("#allRequests").on("click", function () {
          // Remove the custom filter
          $.fn.dataTable.ext.search.pop();
          $("#dtHorizontalExample").DataTable().draw();
        });

        $("#minDate, #maxDate").on("change", function () {
          compareDates();
        });

        $("#clearFilter").on("click", function () {
          $.fn.dataTable.ext.search.pop();
          $("#dtHorizontalExample").DataTable().draw();
          $("#minDate").val("");
          $("#maxDate").val("");
        });

        $(document).ready(function () {
          setTimeout(function () {
            $("#dtHorizontalExample").DataTable({
              pagingType: "full_numbers",
              responsive: true,
              pageLength: 10,
              select: true,
              bDestroy: true,
              lengthMenu: [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
              ],
              columnDefs: [
                {
                  targets: 0,
                  render: function (data, type, row, meta) {
                    return type === "export" ? meta.row + 1 : data;
                  },
                },
              ],
            });

            $("input.global_filter").on("keyup click", function () {
              filterGlobal();
            });

            $("input.column_filter").on("keyup click", function () {
              filterColumn($(this).parents("tr").attr("data-column"));
            });
          }, 0);
        });
      }
    }
  };

  const getErrorReqData = () => {
    ErrorreqService.getErrorReq()
      .then((responsse) => {
        const errorReqData = responsse.data;
        setErrorReqs(responsse.data);

        loadDatatable(errorReqData);

        setResolvedReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "2")
            .length
        );
        setUnresolvedReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "0")
            .length
        );
        setInprogressReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "1")
            .length
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getErrorReqDataById = (uid) => {
    ErrorreqService.getErrorReqById(uid, uid)
      .then((response) => {
        const errorReqData = response.data;
        setErrorReqs(response.data);

        loadDatatable(errorReqData);

        setResolvedReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "2")
            .length
        );
        setUnresolvedReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "0")
            .length
        );
        setInprogressReq(
          errorReqData.filter((errorReqData) => errorReqData.status === "1")
            .length
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLearnersData = () => {
    UserService.getLearners()
      .then((responsse) => {
        setLearners(responsse.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStaffsData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((responsse) => {
        const allStaffs = responsse.data;
        const filteredStaffs = allStaffs.filter(
          (allStaff) => allStaff.role_id !== "1"
        );
        setAdmins(filteredStaffs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getLearnerName(uid, learners) {
    const learner = learners.find((l) => l.user_id === uid);
    return learner ? learner.first_name + " " + learner.last_name : "";
  }

  function getUserName(uid, admins) {
    const admin = admins.find((l) => l.user_id === uid);
    return admin ? admin.first_name + " " + admin.last_name : "";
  }

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getLearnersData();
    getStaffsData(user.user);
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setRoleid(user.role_id);
      setUserId(user.user);
    }

    if (user.role_id === "4") {
      getErrorReqDataById(user.user);
    } else {
      getErrorReqData();
    }
  }, []);

  const assignRequest = () => {
    ErrorreqService.asssinErrorReq(assignReqData)
      .then((resp) => {
        if (resp.data) {
          toast.success("Request Assigned Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShow(false);
        }
        const user = AuthService.getCurrentUser();
        getErrorReqData(user.user);
      })
      .catch((e) => console.log(e));
  };

  const chnageReqSts = () => {
    ErrorreqService.solveErrorReq(solveReqData)
      .then((resp) => {
        if (resp.data) {
          toast.success("Change Request Status Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShowChnagests(false);
        }
        const user = AuthService.getCurrentUser();
        getErrorReqData(user.user);
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      name: "Issue Category",
      selector: (row) => row.issue_cat,
      sortable: true,
      width: "160px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
      width: "600px",
    },
    {
      name: "Attachment",
      cell: (row) =>
        row.attachment ? (
          <span className="role">
            <a
              target="_blank"
              href={"https://elearning.gaa.aero/cit/" + row.attachment}
            >
              <img
                src={"https://elearning.gaa.aero/cit/" + row.attachment}
                width="100px"
                alt=""
              />
            </a>
          </span>
        ) : (
          <span>NA</span>
        ),
      selector: (row) => row.attachment,
      sortable: true,
      width: "150px",
    },
    {
      name: "Raised On",
      cell: (row) => {
        const [date, time] = row.created_on.split(" ");
        return <span>{date}</span>;
      },
      selector: (row) => row.created_on,
      sortable: true,
      width: "130px",
    },
    {
      name: "Raised By",
      cell: (row) => (
        <div className="user-flex">
          <div className="user-info">
            <span className="name">{getUserName(row.raised_by, learners)}</span>
          </div>
        </div>
      ),
      selector: (row) => row.organization,
      sortable: true,
      width: "225px",
      omit: roleid !== "4"
    },

    {
      name: "Asigned To",
      cell: (row) =>
        row.assigned_to !== "0" ? (
          <>
            <div className="user-flex">
              <div className="user-info">
                <span className="name">
                  {getLearnerName(row.assigned_to, admins)}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <span>Not Assigned</span>
          </>
        ),
      selector: (row) => row.assigned_to,
      sortable: true,
      width: "150px",
    },

    {
      name: "Status",
      cell: (row) => (
        <div
          className={
            row.status === "0"
              ? "user-status unresolved"
              : row.status === "1"
              ? "user-status inprogress"
              : "user-status resolved"
          }
        >
          <span style={{ opacity: 0 }}>{row.status}</span>
        </div>
      ),
      selector: (row) => row.course_count,
      sortable: true,
      width: "100px",
    },
    {
      name: "Solved On",
      cell: (row) =>
        row.status === "2" && (
          <>
            <span>{row.solved_on}</span>
          </>
        ),
      selector: (row) => row.status,
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      cell: (row) =>
        roleid !== "4" &&
        (roleid === "1" ? (
          <>
            {row.assigned_to === "0" ? (
              <>
                <div className="assign-req input-box">
                  <div className="select">
                    <select
                      name=""
                      id=""
                      onChange={(e) => {
                        handleShow(e, row.id);
                      }}
                      /// onChange={handleShow}
                    >
                      <option value="">Assign to</option>
                      {Array.isArray(admins)
                        ? admins.map((admin) => (
                            <option key={admin.id} value={admin.user_id}>
                              {admin.first_name}
                              {admin.last_name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span>Assigned</span>
              </>
            )}
          </>
        ) : roleid !== "4" ? (
          <>
            {row.assigned_to === userId && row.status !== "2" ? (
              <>
                <div className="assign-req input-box">
                  <div className="select">
                    <select
                      name=""
                      id=""
                      onChange={(e) => {
                        handleShowSts(e, row.id);
                      }}
                    >
                      <option value="0">Change Status</option>
                      <option value="1" selected>
                        Pending
                      </option>
                      <option value="2">Solved</option>
                      <option value="0">Not Solved</option>
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )),
      selector: (row) => row.status,
      sortable: true,
      omit: roleid !== "4"
    },
  ];

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filteredData = errorReqs.filter(
      (row) =>
        row.first_name.toLowerCase().includes(keyword) ||
        row.last_name.toLowerCase().includes(keyword) ||
        row.email.toLowerCase().includes(keyword) ||
        row.organization.toLowerCase().includes(keyword)
    );
    if (keyword.length > 0) {
      setFilter(true);
    } else {
      setFilter(false);
    }
    setFilteredData(filteredData);
  };

  const handleFromDate = (e) => {
    setIsFromDate(true);
    setFromDate(e.target.value);
    applyFilters(e.target.value, toDate);
  };

  const handleToDate = (e) => {
    setToDate(e.target.value);
    applyFilters(fromDate, e.target.value);
  };

  const applyFilters = (fromDate, toDate) => {
    let filteredData = errorReqs;

    if (fromDate) {
      filteredData = errorReqs.filter(
        (row) => row.created_on.split(" ")[0] == fromDate
      );
    }

    if (fromDate && toDate) {
      filteredData = errorReqs.filter(
        (row) =>
          row.created_on.split(" ")[0] >= fromDate &&
          row.created_on.split(" ")[0] <= toDate
      );
    }

    setFilter(true);
    setFilteredData(filteredData);
  };

  const filterData = (sts) => {
    console.log(sts);
    if (sts === "all") {
      setFilteredData(errorReqs);
    } else {
      const filteredData = errorReqs.filter((row) => row.status == sts);
      if (sts !== "all") {
        setFilter(true);
      } else {
        setFilter(false);
      }
      setFilteredData(filteredData);
    }
  };

  const clearFilter = () => {
    setFilter(false);
    setIsFromDate(false);
    setFromDate("");
    setToDate("");
  };

  return (
    <>
      <div className="container-fluid error">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex errorreq">
                  <div className="heading">
                    <h1>Error Reports and Service Requests</h1>
                  </div>
                  <ToastContainer />
                  {roleid !== "1" ? (
                    <>
                      <div className="courses-act">
                        <Link to="/RaiseRequest" className="action-item">
                          <div>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 28.345 28.343"
                              >
                                <g
                                  id="add"
                                  transform="translate(-72.15 439.827)"
                                >
                                  <path
                                    id="Path_13773"
                                    data-name="Path 13773"
                                    d="M85.636-439.709a2.284,2.284,0,0,0-.663.447c-.594.594-.578.385-.578,6.375v5.3h-5.3c-5.99,0-5.782-.015-6.375.578a1.889,1.889,0,0,0,0,2.7c.594.594.385.578,6.375.578h5.3v5.3c0,5.99-.015,5.782.578,6.375a1.889,1.889,0,0,0,2.7,0c.594-.594.578-.385.578-6.375v-5.3h5.3c5.99,0,5.782.015,6.375-.578a1.889,1.889,0,0,0,0-2.7c-.594-.594-.385-.578-6.375-.578h-5.3v-5.3c0-5.99.015-5.782-.578-6.375A1.921,1.921,0,0,0,85.636-439.709Z"
                                    transform="translate(0 0)"
                                  />
                                </g>
                              </svg>
                            </span>
                            &nbsp;&nbsp;
                            <span>Raise Request</span>
                          </div>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="filter reports">
                  <div className="filter-heading">
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.432"
                        height="15.819"
                        viewBox="0 0 24.503 22.236"
                      >
                        <g
                          id="setting-_1_"
                          transform="translate(-21.408 468.876)"
                        >
                          <path
                            id="Path_12194"
                            data-name="Path 12194"
                            d="M365.507-468.8A3.342,3.342,0,0,0,363-465.566a3.082,3.082,0,0,0,.366,1.541,3.339,3.339,0,0,0,3.338,1.781,3.279,3.279,0,0,0,2.016-1.008,3.023,3.023,0,0,0,.742-1.175,3.384,3.384,0,0,0-.935-3.646,4.2,4.2,0,0,0-1.426-.731A4.3,4.3,0,0,0,365.507-468.8Z"
                            transform="translate(-323.75 0)"
                          />
                          <path
                            id="Path_12195"
                            data-name="Path 12195"
                            d="M22.212-426.671a1.244,1.244,0,0,0-.428.261,1.076,1.076,0,0,0,.235,1.786l.277.136h6.863c5.281,0,6.916-.016,7.083-.063a1.188,1.188,0,0,0,.705-.736,1.117,1.117,0,0,0-.507-1.275l-.245-.146-6.895-.01C24.86-426.723,22.337-426.707,22.212-426.671Z"
                            transform="translate(0 -39.955)"
                          />
                          <path
                            id="Path_12196"
                            data-name="Path 12196"
                            d="M28.6-319.677a3.464,3.464,0,0,0-2.236,1.734l-.225.434h-1.88a8.787,8.787,0,0,0-2.074.078,1.114,1.114,0,0,0,0,2.037,8.787,8.787,0,0,0,2.074.078h1.88l.225.428a3.339,3.339,0,0,0,3.15,1.8,3.343,3.343,0,0,0,2.988-2.309,2.628,2.628,0,0,0,.136-1.019,2.593,2.593,0,0,0-.141-1.029,3.359,3.359,0,0,0-2.131-2.142A3.734,3.734,0,0,0,28.6-319.677Z"
                            transform="translate(-0.111 -141.345)"
                          />
                          <path
                            id="Path_12197"
                            data-name="Path 12197"
                            d="M278.32-276.916a1.113,1.113,0,0,0,.094,2.058c.136.042,1.661.052,4.931.042l4.732-.016.23-.157a1.109,1.109,0,0,0-.178-1.933,38.806,38.806,0,0,0-4.91-.078A39.328,39.328,0,0,0,278.32-276.916Z"
                            transform="translate(-242.883 -181.854)"
                          />
                          <path
                            id="Path_12198"
                            data-name="Path 12198"
                            d="M280.146-170.548a3.075,3.075,0,0,0-1.88.966,3.127,3.127,0,0,0-1,2.142,3.272,3.272,0,0,0,.935,2.5,3.089,3.089,0,0,0,2.209,1.008,3.24,3.24,0,0,0,1.708-.334,3.779,3.779,0,0,0,1.447-1.463l.183-.376,1.807-.005c2.11,0,2.215-.016,2.544-.4a1.073,1.073,0,0,0-.308-1.713l-.277-.136h-3.782l-.063-.188a3.569,3.569,0,0,0-1.588-1.687A3.532,3.532,0,0,0,280.146-170.548Z"
                            transform="translate(-242.489 -282.716)"
                          />
                          <path
                            id="Path_12199"
                            data-name="Path 12199"
                            d="M22.06-127.864a1.075,1.075,0,0,0-.575,1.243,1.2,1.2,0,0,0,.789.8c.178.047,1.473.063,4.758.063,5.124,0,4.889.016,5.249-.4a1.073,1.073,0,0,0-.308-1.713L31.7-128h-9.36Z"
                            transform="translate(-0.042 -323.071)"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="name">Filters</span>
                  </div>
                  <p id="clearFilter" onClick={clearFilter}>
                    Clear Filter
                  </p>
                </div>
                <div className="search-bar form-flex">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                    onChange={handleSearch}
                  />

                  <Form.Group
                    controlId="formBasicOrgName"
                    className="date-input"
                  >
                    <div className="input-box">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="From Date"
                        className="mb-3"
                      >
                        <Form.Control
                          type="date"
                          name="fromDate"
                          value={fromDate}
                          onChange={handleFromDate}
                        />
                      </FloatingLabel>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14.02"
                          viewBox="0 0 81.923 76.58"
                        >
                          <g id="calendar" transform="translate(-15 482)">
                            <path
                              id="Path_13206"
                              data-name="Path 13206"
                              d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                            />
                            <path
                              id="Path_13207"
                              data-name="Path 13207"
                              d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                              transform="translate(0 -108.781)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  {isFromDate && (
                    <Form.Group
                      controlId="formBasicOrgName"
                      className="date-input"
                    >
                      <div className="input-box">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="To Date"
                          className="mb-3"
                        >
                          <Form.Control
                            type="date"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDate}
                          />
                        </FloatingLabel>
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="14.02"
                            viewBox="0 0 81.923 76.58"
                          >
                            <g id="calendar" transform="translate(-15 482)">
                              <path
                                id="Path_13206"
                                data-name="Path 13206"
                                d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                              />
                              <path
                                id="Path_13207"
                                data-name="Path 13207"
                                d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                transform="translate(0 -108.781)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                  )}
                </div>
                <div className="user-status-flex">
                  <div
                    className="user-status all"
                    id="allRequests"
                    onClick={(e) => {
                      filterData("all");
                    }}
                  >
                    <span>All</span>
                    <span>{errorReqs.length}</span>
                  </div>
                  <div
                    className="user-status resolved"
                    id="resolvedRequests"
                    onClick={(e) => {
                      filterData(2);
                    }}
                  >
                    <span>Resolved</span>
                    <span>{resolvedReq}</span>
                  </div>
                  <div
                    className="user-status unresolved"
                    id="unresolvedRequests"
                    onClick={(e) => {
                      filterData(0);
                    }}
                  >
                    <span>Unresolved</span>
                    <span>{unresolvedReq}</span>
                  </div>
                  <div
                    className="user-status inprogress"
                    id="inprogressRequests"
                    onClick={(e) => {
                      filterData(1);
                    }}
                  >
                    <span>In Progress</span>
                    <span>{inprogressReq}</span>
                  </div>
                </div>
                <div className="admin-users courses-list">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filter ? filteredData : errorReqs}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Assign Request</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to assign request?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={assignRequest}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showChnagests} onHide={handleCloseSts} centered>
        <Modal.Header>
          <Modal.Title>Change Request Status</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleCloseSts}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to change request status?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleCloseSts}>
            No
          </button>
          <button className="yes" onClick={chnageReqSts}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <div id="myModal" className="modal">
        <span className="close">&times;</span>
        <img className="modal-content" id="img01" />
        <div id="caption"></div>
      </div>
    </>
  );
};
export default ErrorRequest;
