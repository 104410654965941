import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AuthService from "../../services/auth.service";
import EventService from "../../services/event.service";
import Revenue from "./Charts/Revenue";
import StudentListChart from "./Charts/StudentListChart";
import PaidInvoiceChart from "./Charts/PaidInvoiceChart";
import { useSelector, useDispatch } from "react-redux";

import InvoiceService from "../../services/invoice.service";

const AccountDashboard = () => {
  const [invoiceGraphData, setInvoiceGraphData] = useState({
    approved: "",
    pending: "",
    onHold: "",
    paid: "",
    unpaid: "",
  });

  const [revenue, setRevenue] = useState([]);
  const themeMode = useSelector((state) => state.themeReducer.themeMode);
  const [date, setDate] = useState(new Date());
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);

  const [yAxisData, setYAxisData] = useState([]);
  const [defaultReportFilter, setDefaultReportFilter] = useState("");

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getMonths();
    getWeeks();
    getInvoiceGraphData();

    InvoiceService.revenueGraph()
      .then((resp) => {
        setRevenue(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);

      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    });

    setMonths(formattedDates);
    setYAxisData(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const getInvoiceGraphData = () => {
    InvoiceService.invoiceGraph()
      .then((resp) => {
        const statusCounts = resp.data.statusCounts;
        setInvoiceGraphData({
          approved:
            parseInt(statusCounts.approved) + parseInt(statusCounts.paid),
          pending: statusCounts.pending,
          onHold: statusCounts.onhold,
          paid: statusCounts.paid,
          unpaid: statusCounts.approved,
        });
      })
      .catch((e) => console.log(e));
  };

  const filterReport = (e) => {
    setDefaultReportFilter(e.target.value);
    if (e.target.value == 0) {
      setYAxisData(lastSevenDates);
    } else if (e.target.value == 1) {
      setYAxisData(weeks);
    } else if (e.target.value == 2) {
      setYAxisData(months);
    }
  };

  return (
    <>
      <div className="container-fluid account">
        <div className="row">
          <div className="col-xl-8 col-lg-8 mb-3">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Monthly Revenue Statistic</h1>
                </div>
              </div>
              <div className="inner-box">
                <Revenue
                  yAxisData={yAxisData}
                  revenue={revenue}
                  themeMode={themeMode}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 mb-3">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Schedule and Submissions</h1>
                </div>
              </div>
              <div className="flex-cal">
                <Calendar onChange={setDate} value={date} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Approved Student List</h1>
                </div>
              </div>
              <div className="inner-box">
                <StudentListChart invoiceGraphData={invoiceGraphData} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Paid Invoice</h1>
                </div>
              </div>
              <div className="inner-box">
                <PaidInvoiceChart invoiceGraphData={invoiceGraphData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountDashboard;
