import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const StudentListChart = ({ invoiceGraphData }) => {
  const data = {
    labels: ["Approved", "Onhold", "Pending"],
    datasets: [
      {
        label: "Invoice Status",
        data: [
          invoiceGraphData.approved,
          invoiceGraphData.onHold,
          invoiceGraphData.pending,
        ],
        backgroundColor: ["#316FFF", "#FBA2A2", "#DBD8D8"],
        borderColor: ["transparent"],
        hoverOffset: 5,
      },
    ],
  };

  const centerText = {
    id: "",
  };

  const options = {
    cutout: 0,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      centerText: [centerText],
    },
  };

  const sliceThickness = {
    id: "sliceThickness",
    beforeDraw(chart, plugins) {
      chart.getDatasetMeta(0).data[1].outerRadius = 100;
      chart.getDatasetMeta(0).data[2].outerRadius = 75;
    },
  };

  return (
    <>
      <div className="error-report d-flex flex-row-reverse align-items-center justify-content-around">
        <div className="my__progress-chart">
          <div className="chart">
            <Doughnut
              data={data}
              options={options}
              plugins={[sliceThickness]}
              width="250px"
              height="250px"
            />
          </div>
        </div>
        <div className="chart__data d-flex flex-column">
          <div className="chart__point">
            <div
              className="chart__color"
              style={{ backgroundColor: "#316FFF" }}
            ></div>
            <div className="chart__text">
              <span>Approved</span>
            </div>
          </div>
          <div className="chart__point">
            <div
              className="chart__color"
              style={{ backgroundColor: "#FBA2A2" }}
            ></div>
            <div className="chart__text">
              <span>On Hold</span>
            </div>
          </div>
          <div className="chart__point">
            <div
              className="chart__color"
              style={{ backgroundColor: "#FC7DAC" }}
            ></div>
            <div className="chart__text">
              <span>Pending</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListChart;
