import React, { useState, useEffect } from "react";
import "./CourseManagement.css";
import "./responsive.css";
import Filters from "./Filters";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import AdminUser from "../../../assets/images/profile.jpg";

import { ColorRing } from "react-loader-spinner";

import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../../services/user.service";
import AuthService from "../../../services/auth.service";
import CourseService from "../../../services/course.service";

import DataTable from "react-data-table-component";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageParticipants = () => {
  const location = useLocation();
  const { cid } = location.state;

  const [checkedState, setCheckedState] = useState([]);

  const [courseEnrollLoading, setCourseEnrollLoading] = useState(false);

  const [learnersWithEnrolledDates, setLearnersWithEnrolledDates] = useState(
    []
  );

  const [learners, setLearners] = useState([]);

  const [learnersCount, setLearnersCount] = useState("");

  const [moduleCount, setModuleCount] = useState("");

  const [cohortsPart, setCohortsPart] = useState({
    enrollInCohorts: [],
    selectedCohorts: [],
    courseid: [],
    stdid: ""
  });

  const [learnerToModify, setLearnerToModify] = useState(null);

  const [currentCourse, setCurrentCourse] = useState({});
  const [userid, setUserId] = useState("");

  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPage(page - 1); // Adjust for zero-based index
  };

  const getCurrentLearnersInCourses = () => {
    UserService.getLearners(userid)
      .then((response) => {
        setLearners(response.data);
        const learnersData = response.data;
        setLearners(learnersData);

        CourseService.getStudentsMaster(userid)
          .then((res) => {
            const studentMaster = res.data;

            const courseids = studentMaster.filter((students) =>
              students.course_id.split(",").includes(cid)
            );

            setLearnersCount(courseids.length);

            setCheckedState(
              learnersData.map((learner) => ({
                id: learner.id,
                checked: courseids.some((courseid) => courseid.std_id === learner.id),
              }))
            );

            // setCheckedState(
            //   learnersData.map((learner) =>
            //     courseids.some((courseid) => courseid.std_id === learner.id)
            //   )
            // );
            

            // setCheckedState(
            //   Array.from({ length: learnersData.length }, (_, index) =>
            //     courseids.some(
            //       (courseid) => courseid.std_id === learnersData[index].id
            //     )
            //   )
            // );

            const enrolledDates = learnersData.map((learner) => {
              const course = courseids.find(
                (course) => course.std_id === learner.id
              );
              return course ? course.enrolled_date : null;
            });

            const updatedArray = learnersData.map((item, index) => {
              return {
                ...item, // Copy the existing object
                enrolled_date: enrolledDates[index], // Update the 'age' property with the value from newArray
              };
            });

            setLearnersWithEnrolledDates(updatedArray);

            setLearners(updatedArray);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log(e);
        setCheckedState([]);
      });
  };

  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [isFromDate, setIsFromDate] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div className="user-flex">
          <div className="user-pic">
            <img src={AdminUser} width="40px" alt="" />
          </div>
          <div className="user-info">
            <span className="name">
              {row.first_name} {row.last_name}
            </span>
            <span className="role">{row.email}</span>
          </div>
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      width: "350px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div
          className={
            row.status === "1" && row.pubsts === "1"
              ? "user-status cstatus active"
              : row.status === "1" || row.pubsts === "0"
              ? "user-status cstatus draft"
              : "user-status cstatus inactive"
          }
        >
          <span>{row.pubsts !== "1" ? "2" : row.status}</span>
        </div>
      ),
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Enrolled On",
      selector: (row) => row.organization,
      sortable: true,
    },
    {
      name: "Organization",
      selector: (row) => row.organization,
      sortable: true,
    },
    {
      name: "Courses Enrolled",
      cell: (row) => (
        <span>{row.course_count === null ? 0 : row.course_count}</span>
      ),
      selector: (row) => row.course_count,
      sortable: true,
      width: "200px"
    },
    {
      name: "Action",
      cell: (row, index) => {
        const globalIndex = currentPage * pageSize + index;

        // console.log("globalIndex", globalIndex)
        return (
        
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`learner-${row.id}`}
            value={row.id}
            checked={checkedState.some(item => item.id == row.id && item.checked)}
            onChange={() => changeStatus(row.id, row.id)}
          />
          <label
            className="form-check-label"
            htmlFor="flexSwitchCheckDefault"
          ></label>
        </div>
      )},
      selector: (row) => row.id,
      sortable: true,
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const keyword = e.target.value.toLowerCase();
      const filteredData = learners.filter(
        (row) =>
          row.first_name.toLowerCase().includes(keyword) ||
          row.last_name.toLowerCase().includes(keyword) ||
          row.email.toLowerCase().includes(keyword) 
      );
      if (keyword.length > 0) {
        setFilter(true);
      } else {
        setFilter(false);
      }
      setFilteredData(filteredData);
  };

  const handleFromDate = (e) => {
    setIsFromDate(true);
    setFromDate(e.target.value);
    const filteredData = learners.filter(
      (row) => row.created_on.split(" ")[0] == e.target.value
    );
    console.log("filteredData", filteredData);
    setFilter(true);
    setFilteredData(filteredData);
  };

  const handleToDate = (e) => {
    setToDate(e.target.value);
    const filteredData = learners.filter(
      (row) =>
        row.created_on.split(" ")[0] >= fromDate &&
        row.created_on.split(" ")[0] <= e.target.value
    );

    setFilter(true);
    setFilteredData(filteredData);
  };

  const clearFilter = () => {
    setFilter(false);
    setFromDate("");
    setToDate("");
    setSearch("");
    setIsFromDate(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUserId(user.user);

    getCurrentLearnersInCourses();

    CourseService.getCourseById(user.user, cid)
      .then((response) => {
        setModuleCount(response.data.activity_count);
        setCurrentCourse(response.data.course_info[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);

  const handleAddClose = (e) => {
    e.preventDefault();
    setAdd(false);
    getCurrentLearnersInCourses();
  };
  const handleAddShow = (e) => {
    e.preventDefault();
    setAdd(true);
  };

  const handleRemoveClose = (e) => {
    e.preventDefault();
    setRemove(false);
    getCurrentLearnersInCourses();
  };
  const handleRemoveShow = (e) => {
    e.preventDefault();
    setRemove(true);
  };

  const changeStatus = (userid, uid) => {
    setLearnerToModify(userid);

    setCohortsPart((prevState) => ({
      ...prevState,
      stdid: uid,
    }));

    const item = checkedState.find((state) => state.id === userid);

    if (!item || item.checked === false || checkedState.length === 0) {
      setAdd(true);
      setRemove(false);
    } else {
      setAdd(false);
      setRemove(true);
    }

    // if (checkedState[userid] === false || checkedState.length === 0) {
    //   setAdd(true);
    //   setRemove(false);
    // } else {
    //   setRemove(true);
    //   setAdd(false);
    // }
  };

  const addLearner = () => {
    if (learnerToModify !== null) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === learnerToModify ? !item : item
      );

      setCheckedState(updatedCheckedState);
    }

    const data = {
      usp: userid,
      csid: cid,
      std: cohortsPart.stdid,
    };

    setCourseEnrollLoading(true);

    CourseService.addCourseInStudents(data)
      .then((resp) => {
        toast.success("Learner Added to Course Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setCourseEnrollLoading(false);

        setAdd(false);

        getCurrentLearnersInCourses();
      })
      .catch((e) => console.log(e));
  };

  const removeLearner = () => {
    const data = {
      usp: userid,
      corsid: cid,
      std: cohortsPart.stdid,
    };

    setCourseEnrollLoading(true);

    CourseService.removeCourseInStudents(data)
      .then((resp) => {
        toast.success("Learner Removed to Course Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setCourseEnrollLoading(false);

        setRemove(false);

        getCurrentLearnersInCourses();
      })
      .catch((e) => console.log(e));
  };

  console.log("checkedState", checkedState)

  const idsWithCheckedTrue = checkedState
    .filter((state) => state.checked) // Filter only the objects where checked is true
    .map((state) => state.id); // Map to extract the ids

  console.log("IDs with checked as true:", idsWithCheckedTrue);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters
              handleOccCat={null}
              handleCourseCat={null}
              handleFilterSubmit={null}
              occupCat={null}
              courseCat={null}
              filterOccupCat={null}
              filterCourseCat={null}
              clearFilter={clearFilter}
              fromDate={fromDate}
              toDate={toDate}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              isFromDate={isFromDate}
            />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <Link to="/ManageCourses" className="action-item back">
                    <div>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 19.379 19.382"
                        >
                          <g
                            id="left-arrow-in-circular-button-black-symbol"
                            transform="translate(-0.6 511.567)"
                          >
                            <path
                              id="Path_13057"
                              data-name="Path 13057"
                              d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                              transform="translate(0 0)"
                            />
                          </g>
                        </svg>
                      </span>
                      &nbsp; &nbsp;
                      <span>Back</span>
                    </div>
                  </Link>
                  <div className="action-item">
                    <span>{currentCourse.name}</span>
                  </div>
                  <div className="action-item">
                    <span>Participants : {learnersCount}</span>
                  </div>
                  <div className="action-item">
                    <span>Modules : {moduleCount}</span>
                  </div>
                </div>
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <ToastContainer />
                <div className="admin-users cohorts">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={filter ? filteredData : learners}
                      pagination
                      paginationPerPage={pageSize}
                      onChangePage={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {courseEnrollLoading ? (
        <>
          <div className="loading load-anim">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <Modal show={add} onHide={handleAddClose} centered>
        <Modal.Header>
          <Modal.Title>Add Learner in Course</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleAddClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Add this Learner in course?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="no" onClick={handleAddClose}>
            No
          </button>
          <button className="yes" onClick={addLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header>
          <Modal.Title>Remove Learner from course</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleRemoveClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this Learner from this course?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleRemoveClose}>
            No
          </button>
          <button className="yes" onClick={removeLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageParticipants;
